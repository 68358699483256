// src/services/AuthService.js

import axios from "axios";

const url = "https://apidata.satudatapariamankota.id/api/";
const urlpublik =
  "https://apidata.satudatapariamankota.id/api/DataPublik/kelurahan/25/";

const urlsts =
  "https://apidata.satudatapariamankota.id/api/DataStatistik/kelurahan/25/";

export default {
  dashboard() {
    return axios.get(urlpublik + "dashboard").then((response) => response);
  },
  getDataUmurKat() {
    return axios.get(urlpublik + "getDataUmurKat").then((response) => response);
  },
  getDataLahan() {
    return axios.get(urlpublik + "getDataLahan").then((response) => response);
  },
  getDataJalanbaru() {
    return axios.get(urlpublik + "getDataJalan").then((response) => response);
  },
  getDataBangunan() {
    return axios
      .get(urlpublik + "getDataBangunan")
      .then((response) => response);
  },
  getDataRumahTinggalIsi() {
    return axios
      .get(urlpublik + "getDataRumahTinggalIsi")
      .then((response) => response);
  },
  getDataRumahTinggalIsiI() {
    return axios
      .get(urlpublik + "getDataRumahTinggalIsiI")
      .then((response) => response);
  },
  getDataRumahTinggalIsiII() {
    return axios
      .get(urlpublik + "getDataRumahTinggalIsiII")
      .then((response) => response);
  },
  getDataRumahTinggalKosong() {
    return axios
      .get(urlpublik + "getDataRumahTinggalKosong")
      .then((response) => response);
  },
  getDataWirausaha() {
    return axios
      .get(urlpublik + "getDataWirausaha")
      .then((response) => response);
  },
  getDataSaranaUmum() {
    return axios
      .get(urlpublik + "getDataSaranaUmum")
      .then((response) => response);
  },
  getDataSPTPBB() {
    return axios.get(urlpublik + "getDataSPTPBB").then((response) => response);
  },
  getDataPenduduk() {
    return axios
      .get(urlpublik + "getDataPenduduk")
      .then((response) => response);
  },
  getDataPendudukk() {
    return axios
      .get(urlpublik + "getDataPendudukk")
      .then((response) => response);
  },
  getDataUmur() {
    return axios.get(urlpublik + "getDataUmur").then((response) => response);
  },
  getPesertaKesehatan() {
    return axios
      .get(urlpublik + "getPesertaKesehatan")
      .then((response) => response);
  },
  getDataPendidikan() {
    return axios
      .get(urlpublik + "getDataPendidikan")
      .then((response) => response);
  },
  getDataSagasaja() {
    return axios
      .get(urlpublik + "getDataSagasaja")
      .then((response) => response);
  },
  getDataBantuanSosial() {
    return axios
      .get(urlpublik + "getDataBantuanSosial")
      .then((response) => response);
  },
  getDataDasmawisma() {
    return axios
      .get(urlpublik + "getDataDasmawisma")
      .then((response) => response);
  },
  getKelahiranBayi() {
    return axios
      .get(urlpublik + "getKelahiranBayi")
      .then((response) => response);
  },
  getPosyandu() {
    return axios.get(urlpublik + "getPosyandu").then((response) => response);
  },
  getPertanian() {
    return axios.get(urlpublik + "getPertanian").then((response) => response);
  },
  getPeternakan() {
    return axios.get(urlpublik + "getPeternakan").then((response) => response);
  },
  getPerikanan() {
    return axios.get(urlpublik + "getPerikanan").then((response) => response);
  },

  // STATISTIK
  stsgetDataLahan() {
    return axios.get(urlsts + "getDataLahan").then((response) => response);
  },
  stsDataJalan() {
    return axios.get(urlsts + "getDataJalan").then((response) => response);
  },
  stsDataBangunan() {
    return axios.get(urlsts + "getDataBangunan").then((response) => response);
  },
  stsDataRumahTinggalIsi() {
    return axios
      .get(urlsts + "getDataRumahTinggalIsi")
      .then((response) => response);
  },
  stsDataRumahTinggalIsiI() {
    return axios
      .get(urlsts + "getDataRumahTinggalIsiI")
      .then((response) => response);
  },
  stsDataRumahTinggalIsiII() {
    return axios
      .get(urlsts + "getDataRumahTinggalIsiII")
      .then((response) => response);
  },
  stsDataRumahTinggalKosong() {
    return axios
      .get(urlsts + "getDataRumahTinggalKosong")
      .then((response) => response);
  },

  stsDataWirausaha() {
    return axios.get(urlsts + "getDataWirausaha").then((response) => response);
  },
  stsDataSaranaUmum() {
    return axios.get(urlsts + "getDataSaranaUmum").then((response) => response);
  },

  stsDataSPTPBB() {
    return axios.get(urlsts + "getDataSPTPBB").then((response) => response);
  },
  stsDataPenduduk() {
    return axios.get(urlsts + "getDataPenduduk").then((response) => response);
  },
  stsDataPendudukk() {
    return axios.get(urlsts + "getDataPendudukk").then((response) => response);
  },
  stsDataUmur() {
    return axios.get(urlsts + "getDataUmur").then((response) => response);
  },
  stsPesertaKesehatan() {
    return axios
      .get(urlsts + "getPesertaKesehatan")
      .then((response) => response);
  },
  stsDataPendidikan() {
    return axios.get(urlsts + "getDataPendidikan").then((response) => response);
  },
  stsDataSagasaja() {
    return axios.get(urlsts + "getDataSagasaja").then((response) => response);
  },
  stsDataBantuanSosial() {
    return axios
      .get(urlsts + "getDataBantuanSosial")
      .then((response) => response);
  },
  stsDataDasmawisma() {
    return axios.get(urlsts + "getDataDasmawisma").then((response) => response);
  },
  stsKelahiranBayi() {
    return axios.get(urlsts + "getKelahiranBayi").then((response) => response);
  },

  stsPosyandu() {
    return axios.get(urlsts + "getPosyandu").then((response) => response);
  },
  stsPertanian() {
    return axios.get(urlsts + "getPertanian").then((response) => response);
  },
  stsPeternakan() {
    return axios.get(urlsts + "getPeternakan").then((response) => response);
  },
  stsPerikanan() {
    return axios.get(urlsts + "getPerikanan").then((response) => response);
  },
};
